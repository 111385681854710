import React from 'react';
import EbayLogoFull from '../images/ebay-logo.png';
import EbayLogoMini from '../images/logos/ebay_mini.png';

import Box from './Box';

interface EbayLogoProps {
  size?: number;
  isMini?: boolean;
}

const EbayLogo = ({ size = 110, isMini = false }: EbayLogoProps) => {
  return (
    <Box
      center
      height={isMini ? size : size / 2}
      width={size}
      overflow="hidden"
    >
      {isMini ? (
        <img
          style={{ width: size, height: size }}
          src={EbayLogoMini}
          alt={'logo'}
        />
      ) : (
        <img
          style={{ width: size, height: size, marginTop: -(size / 4) }}
          src={EbayLogoFull}
          alt={'logo'}
        />
      )}
    </Box>
  );
};

export default EbayLogo;
