const env = process.env.NODE_ENV;

const isProd = env === 'production';

export const isStipeTest = true;

export interface SubscriptionPlan {
  id: string;
  successUrl: string;
  cancelUrl: string;
  price: number;
  currency: string;
  interval: 'month' | 'year';
  name: string;
  description: string;
  features: string[];
}

export interface SubscriptionProducts {
  starter: {
    monthly: SubscriptionPlan,
    yearly: SubscriptionPlan,
  };
  pro: {
    monthly: SubscriptionPlan,
    yearly: SubscriptionPlan,
  };
}

export const SUBSCRIPTION_PRODUCTS: SubscriptionProducts = {
  starter: {
    monthly: {
      id: !isStipeTest ? '' : 'price_1QDbE5LBYDqfrJYhGuWG8IXc',
      successUrl: isProd
        ? 'https://dropcompare.com/'
        : 'https://localhost:3000',
      cancelUrl: isProd ? 'https://dropcompare.com/' : 'https://localhost:3000',
      price: 19,
      currency: '$',
      interval: 'month',
      name: 'Starter',
      description: 'Starter subscription',
      features: [
        'Compare prices across 3 markets',
        'AI-powered product research & SEO',
        'Smart product suggestions',
        'One-click import to eBay',
        'Unlock profitable niches',
        '15,000 credits',
        'List up to 200 items on eBay',
      ],
    },
    yearly: {
      id: !isStipeTest ? '' : 'price_1QDruDLBYDqfrJYhK7RuRFkC',
      successUrl: isProd
        ? 'https://dropcompare.com/'
        : 'https://localhost:3000',
      cancelUrl: isProd ? 'https://dropcompare.com/' : 'https://localhost:3000',
      price: 180,
      currency: '$',
      interval: 'year',
      name: 'Starter',
      description: 'Starter subscription',
      features: [
        'Compare prices across 3 markets',
        'AI-powered product research & SEO',
        'Smart product suggestions',
        'One-click import to eBay',
        'Unlock profitable niches',
        '15,000 credits',
        'List up to 200 items on eBay',
      ],
    },
  },
  pro: {
    monthly: {
      id: !isStipeTest ? '' : 'price_1QDrv1LBYDqfrJYhl0kG60Tl',
      successUrl: isProd ? 'https://dropcompare.com/' : 'http://localhost:3000',
      cancelUrl: isProd ? 'https://dropcompare.com/' : 'http://localhost:3000',
      price: 35,
      currency: '$',
      interval: 'month',
      name: 'Pro',
      description: 'Pro subscription',
      features: [
        'Compare prices across 3 markets',
        'AI-powered product research & SEO',
        'Smart product suggestions',
        'One-click import to eBay',
        'Unlock profitable niches',
        '25,000 credits',
        'List up to 800 items on eBay',
        'Collaborate with your team',
      ],
    },
    yearly: {
      id: !isStipeTest ? '' : 'price_1QDrveLBYDqfrJYhU5dBJRW7',
      successUrl: isProd ? 'https://dropcompare.com/' : 'http://localhost:3000',
      cancelUrl: isProd ? 'https://dropcompare.com/' : 'http://localhost:3000',
      price: 336,
      currency: '$',
      interval: 'year',
      name: 'Pro',
      description: 'Pro subscription',
      features: [
        'Compare prices across 3 markets',
        'AI-powered product research & SEO',
        'Smart product suggestions',
        'One-click import to eBay',
        'Unlock profitable niches',
        '25,000 credits',
        'List up to 800 items on eBay',
        'Collaborate with your team',
      ],
    },
  },
};
