import React from 'react';
import { Box, Typography, Paper, Grid, Divider } from '@mui/material';
import TokenIcon from '@mui/icons-material/Token';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import SearchIcon from '@mui/icons-material/Search';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';

const CreditSystem = () => {
  const FeatureCard = ({
    title,
    credits,
    icon,
  }: {
    title: string,
    credits: string,
    icon: React.ReactNode,
  }) => (
    <Paper
      elevation={0}
      sx={{
        p: 3,
        borderRadius: 2,
        border: '1px solid #e0e0e0',
        transition: 'all 0.3s ease',
        height: '100%',
        '&:hover': {
          boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
        },
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, gap: 1 }}>
        {React.cloneElement(icon as React.ReactElement, {
          sx: { color: '#2196F3' },
        })}
        <Typography variant="h6" sx={{ fontWeight: 600, color: '#333' }}>
          {title}
        </Typography>
      </Box>
      <Typography variant="body1" sx={{ color: '#666' }}>
        {credits}
      </Typography>
    </Paper>
  );

  return (
    <Box
      sx={{
        p: { xs: 2, sm: 4 },
        textAlign: 'center',
        maxWidth: 1000,
        margin: 'auto',
        backgroundColor: 'white',
      }}
    >
      <Typography
        variant="h4"
        sx={{
          fontWeight: 800,
          mb: 1,
          color: '#2196F3',
        }}
      >
        Credit System
      </Typography>

      <Typography
        variant="body1"
        sx={{
          mb: 4,
          color: '#666',
          maxWidth: '600px',
          margin: 'auto',
        }}
      >
        DropCompare uses credits to access various features. Depending on your
        plan, you`ll receive a set number of credits each month.
      </Typography>

      <Grid container spacing={3} mt={2} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6}>
          <FeatureCard
            title="Amazon Search"
            credits="30 credits per request"
            icon={<SearchIcon />}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FeatureCard
            title="Compare Products"
            credits="10 credits per product"
            icon={<CompareArrowsIcon />}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FeatureCard
            title="AI Consulting"
            credits="10 credits per message"
            icon={<SmartToyIcon />}
          />
        </Grid>
      </Grid>

      <Paper
        elevation={0}
        sx={{
          p: 4,
          borderRadius: 2,
          border: '1px solid #e0e0e0',
          mb: 4,
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: 700, mb: 3, color: '#2196F3' }}
        >
          Monthly Credit Allowance
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Paper
              elevation={0}
              sx={{
                p: 3,
                borderRadius: 2,
                border: '1px solid #e0e0e0',
                height: '100%',
              }}
            >
              <WorkspacePremiumIcon sx={{ color: '#2196F3', mb: 1 }} />
              <Typography variant="h6" sx={{ fontWeight: 600, color: '#333' }}>
                Basic Plan
              </Typography>
              <Typography
                variant="h4"
                sx={{ fontWeight: 800, color: '#2196F3', my: 2 }}
              >
                15,000
              </Typography>
              <Typography variant="body2" sx={{ color: '#666' }}>
                credits per month
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper
              elevation={0}
              sx={{
                p: 3,
                borderRadius: 2,
                border: '1px solid #e0e0e0',
                height: '100%',
              }}
            >
              <WorkspacePremiumIcon sx={{ color: '#2196F3', mb: 1 }} />
              <Typography variant="h6" sx={{ fontWeight: 600, color: '#333' }}>
                Pro Plan
              </Typography>
              <Typography
                variant="h4"
                sx={{ fontWeight: 800, color: '#2196F3', my: 2 }}
              >
                25,000
              </Typography>
              <Typography variant="body2" sx={{ color: '#666' }}>
                credits per month
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Paper>

      <Paper
        elevation={0}
        sx={{
          p: 4,
          borderRadius: 2,
          border: '1px solid #e0e0e0',
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: 700, mb: 3, color: '#2196F3' }}
        >
          Example Usage
        </Typography>
        <Typography variant="body1" sx={{ mb: 2, color: '#666' }}>
          With the <strong>Basic Plan</strong> (15,000 credits), you can:
        </Typography>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sm={6}>
            <Box sx={{ textAlign: 'left', pl: 2 }}>
              <Typography
                variant="body1"
                sx={{
                  color: '#666',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  mb: 1,
                }}
              >
                <TokenIcon sx={{ color: '#2196F3', fontSize: '1rem' }} />
                Compare 1,000 products
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: '#666',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  mb: 1,
                }}
              >
                <TokenIcon sx={{ color: '#2196F3', fontSize: '1rem' }} />
                Perform 500 Amazon Searches
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: '#666',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <TokenIcon sx={{ color: '#2196F3', fontSize: '1rem' }} />
                Mix and match features as needed!
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default CreditSystem;
