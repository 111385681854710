import {
  Box,
  Button,
  Card,
  Grid2,
  List,
  ListItem,
  MenuItem,
  Modal,
  Select,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { CENTER } from '../../utils/CONSTS/PRESTYLES';
import EbayLogo from '../EbayLogo';
import { type ModalStateType } from '../../stateManagement/ModalStateManager';
import { ebayAuthUrl } from './TokenSateComponent';
import { Add, Check, CheckBox, ChevronRight } from '@mui/icons-material';
import { useReactiveVar } from '@apollo/client';
import { ActiveMarketPlace } from '../../stateManagement/ActiveMarketPlace';
import { useNavigate } from 'react-router-dom';
import {
  addDoc,
  collection,
  doc,
  getDocs,
  getFirestore,
  updateDoc,
} from 'firebase/firestore';
import { SelectedOrganisationAccountState } from '../../stateManagement/SelectedOrganisationAccount';
import { getFunctions, httpsCallableFromURL } from 'firebase/functions';
import { firebaseCall } from '../../utils/CONSTS/ENDPOINTS';
import { log } from 'node:console';
import { type IOrganisationStore } from '../../utils/TYPES';
import { useTokenValidation } from '../../stateManagement/useTokenValidation';
import { LoadingButton } from '@mui/lab';
import { SelectedStoreState } from '../../stateManagement/SelectedStoreState';
import Loading from '../Loading';

const db = getFirestore();
const functions = getFunctions();
interface AddStoreModalProps {
  visible: boolean;
  onClose: () => void;
  modalState: ModalStateType;
}

const AddStoreModal = ({
  visible,
  onClose,
  modalState,
}: AddStoreModalProps) => {
  const { palette } = useTheme();
  const [addStore, setAddStore] = useState(false);
  const Navigate = useNavigate();
  const storeLocations = [
    {
      id: 'GB',
      country: 'United Kingdom',
      flag: '🇬🇧',
    },
    {
      id: 'US',
      country: 'United States',
      flag: '🇺🇸',
    },
  ];

  const stores = [
    {
      id: 'ebay',
      logo: 'ebay',
      text: 'Ebay',
      disabled: false,
      navigation: 'setup-ebay-account',
    },
    {
      id: 'shopify',
      logo: 'shopify',
      text: 'Shopify',
      disabled: true,
      navigation: null,
    },
    {
      id: 'amazon',
      logo: 'amazon',
      text: 'Amazon',
      disabled: true,
      navigation: null,
    },
  ];

  const [selectedStore, setSelectedStore] = React.useState<string>('');
  const [selectedLocation, setSelectedLocation] = React.useState<string>('GB');

  const activeStore = useReactiveVar(SelectedStoreState);

  const [allStores, setAllStores] = useState<IOrganisationStore[] | undefined>(
    undefined,
  );

  const getAllStores = async () => {
    const storesRef = collection(
      db,
      `organisations/${SelectedOrganisationAccountState()?.id}/stores`,
    );
    const storesSnapshot = await getDocs(storesRef);
    const storesList = storesSnapshot.docs.map((_doc) => ({
      id: _doc?.id,
      ..._doc.data(),
    }));
    setAllStores(storesList as IOrganisationStore[]);
  };

  useEffect(() => {
    getAllStores();
  }, []);

  const createStore = async () => {
    const create = httpsCallableFromURL(functions, firebaseCall('createStore'));
    await create({
      store: selectedStore,
      location: selectedLocation,
    });
  };

  const validate = useTokenValidation();

  const [loading, setLoading] = useState(false);
  const onAddStoreClick = async () => {
    if (!selectedStore || !selectedLocation) return;
    setLoading(true);
    const theStore = stores?.find((store) => store.id === selectedStore);
    if (theStore?.disabled) return;
    await createStore();
    await validate();
    onClose();
    setLoading(false);
    Navigate(theStore?.navigation as string);
  };

  const setActiveStore = async (store: IOrganisationStore) => {
    const orgRef = doc(
      db,
      `organisations/${SelectedOrganisationAccountState()?.id}`,
    );
    await updateDoc(orgRef, {
      activeStoreId: store.id,
    });
    window.location.reload();
  };

  const onStoreClick = async (store: IOrganisationStore) => {
    const storeConfig = stores?.find((_store) => _store.id === store?.store);
    setActiveStore(store);
    if (!store?.email) {
      // need to set up
      Navigate(storeConfig?.navigation as string);
    }
    onClose();
  };

  if (allStores === undefined) return <Loading />;

  return (
    <Modal
      open={visible}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        ...CENTER,
      }}
    >
      <Box
        sx={{
          backgroundColor: palette?.background?.default,
          width: addStore ? 700 : 400,
          overflowY: 'scroll',
          ...CENTER,
          flexDirection: 'column',
        }}
        p={3}
        borderRadius={3}
      >
        {!addStore && (
          <Box>
            {allStores?.length > 0 && (
              <Typography variant="h5" fontWeight={800} textAlign={'center'}>
                Connected {allStores?.length > 1 ? 'Stores' : 'Store'}
              </Typography>
            )}
            <Grid2
              container
              spacing={2}
              sx={{
                marginTop: 2,
                width: '100%',
                justifyContent: 'center',
              }}
            >
              <Grid2 container spacing={2}>
                {allStores?.length > 0 ? (
                  allStores.map((store) => {
                    const isSelected = activeStore?.id === store?.id;

                    return (
                      <Grid2 size={{ xs: 12 }} key={store.id}>
                        <Card
                          onClick={() => {
                            onStoreClick(store);
                          }}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: 2,
                            border: isSelected ? '2px solid' : '1px solid',
                            borderColor: isSelected
                              ? 'primary.main'
                              : 'divider',
                            borderRadius: 2,
                            cursor: 'pointer',
                            '&:hover': {
                              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                            },
                          }}
                        >
                          {isSelected && (
                            <Check
                              sx={{
                                color: 'primary.main',
                                fontSize: 30,
                                mr: 2,
                              }}
                            />
                          )}
                          <Box sx={{ flex: 1 }}>
                            <Typography
                              variant="h6"
                              sx={{ fontWeight: 'bold' }}
                            >
                              {store.store}
                            </Typography>
                            {store.userId && (
                              <Typography
                                variant="body2"
                                sx={{ color: 'text.secondary', mt: 0.5 }}
                              >
                                User ID: {store.userId}
                              </Typography>
                            )}
                            <Typography
                              variant="body2"
                              sx={{ color: 'text.secondary', mt: 0.5 }}
                            >
                              Location: {store.location}
                            </Typography>
                            {!store.email && (
                              <Typography
                                variant="body2"
                                sx={{ color: 'error.main', mt: 0.5 }}
                              >
                                Setup Required
                              </Typography>
                            )}
                          </Box>
                          <ChevronRight sx={{ color: 'text.secondary' }} />
                        </Card>
                      </Grid2>
                    );
                  })
                ) : (
                  <Box sx={{ textAlign: 'center', mt: 2 }}>
                    <Typography variant="h6">
                      No stores found. Add your first store to start listing!
                    </Typography>
                  </Box>
                )}
              </Grid2>
            </Grid2>
            <Box
              sx={{ ...CENTER }}
              mt={2}
              onClick={() => {
                setAddStore(true);
              }}
            >
              <Button startIcon={<Add />}>Add Store</Button>
            </Box>
          </Box>
        )}
        {addStore && (
          <Box>
            <Typography variant="h6">Add Store</Typography>
            <Grid2
              container
              spacing={2}
              sx={{
                marginTop: 2,
                width: '100%',
                justifyContent: 'center',
              }}
            >
              {stores?.map(({ id, text, logo, disabled }) => {
                const selected = selectedStore === id;

                return (
                  <Grid2
                    size={{ xs: 6 }}
                    key={id}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: 200,
                      height: 250,
                      border: '1px solid',
                      borderColor: selected
                        ? palette?.primary?.main
                        : palette?.divider,
                      borderRadius: 2,
                      cursor: 'pointer',
                      position: 'relative',
                    }}
                    onClick={() => {
                      !disabled && setSelectedStore(id);
                    }}
                  >
                    <Box
                      sx={{ textAlign: 'center', opacity: selected ? 1 : 0.5 }}
                    >
                      {selected && (
                        <Box sx={{ position: 'absolute', top: 10, right: 10 }}>
                          <Check sx={{ fontSize: 20 }} />
                        </Box>
                      )}
                      {/* <Typography variant="h2">{logo}</Typography> */}
                      <Typography fontWeight={'bold'}>{text}</Typography>
                      {disabled && <Typography>coming soon</Typography>}
                    </Box>
                  </Grid2>
                );
              })}
            </Grid2>
            <Typography variant="h6" mt={5}>
              Please select the store country
            </Typography>
            <Select
              value={selectedLocation}
              onChange={(e) => {
                setSelectedLocation(e.target.value);
              }}
              sx={{ width: '100%', marginTop: 2 }}
            >
              {storeLocations?.map(({ id, country, flag }) => (
                <MenuItem key={id} value={id} sx={{ color: 'text.primary' }}>
                  {flag} {'  '} {country}
                </MenuItem>
              ))}
            </Select>

            <Box sx={{ ...CENTER }} mt={2}>
              <LoadingButton
                loading={loading}
                endIcon={<ChevronRight />}
                onClick={() => {
                  onAddStoreClick();
                }}
                variant="contained"
                disabled={!selectedStore || !selectedLocation}
              >
                Next
              </LoadingButton>
            </Box>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default AddStoreModal;
