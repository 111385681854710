const WHITE = '#FFFFFF';
const BLACK = '#000000';
const PRIMARY = '#5456ca';
const PRIMARY_DARK = '#13144e';
const PRIMARY_LIGHT = '#d8d8f1';
const PRIMARY_BLUE = '#21CBF3';
const SECONDARY = '#ffad16';
const SECONDARY_DARK = '#E64A19';
const SECONDARY_LIGHT = '#FFCCBC';
const ACCENT = '#FF4081';
const ACCENT_DARK = '#F50057';
const ACCENT_LIGHT = '#FF80AB';
const TEXT = '#212121';
const TEXT_LIGHT = '#757575';
const TEXT_DARK = '#000000';
const DIVIDER = '#BDBDBD';
const BACKGROUND = '#F5F5F5';
const BACKGROUND_LIGHT = '#FAFAFA';
const BACKGROUND_DARK = '#E0E0E0';
const ERROR = '#F44336';

export default {
  WHITE,
  BLACK,
  PRIMARY,
  PRIMARY_DARK,
  PRIMARY_LIGHT,
  SECONDARY,
  SECONDARY_DARK,
  SECONDARY_LIGHT,
  ACCENT,
  ACCENT_DARK,
  ACCENT_LIGHT,
  TEXT,
  TEXT_LIGHT,
  TEXT_DARK,
  DIVIDER,
  BACKGROUND,
  BACKGROUND_LIGHT,
  BACKGROUND_DARK,
  ERROR,
  PRIMARY_BLUE,
};
