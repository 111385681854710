import React from 'react';
import AmazonImage from '../images/aliexpress.png';
import Box from './Box';

interface AliExpressLogoProps {
  size?: number;
}

const AliExpressLogo = ({ size = 110 }: AliExpressLogoProps) => {
  return (
    <Box center>
      <img
        style={{ width: size, height: size / 2.8 }}
        src={AmazonImage}
        alt={'logo'}
      />
    </Box>
  );
};

export default AliExpressLogo;
