/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeVar, useReactiveVar } from '@apollo/client';
import { type User } from 'firebase/auth';

export interface UserInfo extends User {
  darkMode?: boolean;
  organisation?: string;
}

export type userRoleType = 'admin' | 'user';
export interface UserState {
  user: any;
  loading: boolean;
  isEmailVerified: boolean;
  permissionStatus:
    | {
        hasAccess: boolean,
        productId: string | null,
        priceId: string | null,
        subscribedProduct: any,
        role: userRoleType,
      }
    | undefined;
  updateUser: (data: Record<string, string | boolean | number>) => void;
}

export const initialUserState: UserState = {
  user: null,
  loading: false,
  permissionStatus: undefined,
  isEmailVerified: false,
  updateUser: () => {},
};

export const UserStateManagerState = makeVar<UserState>(initialUserState);

export const useUser = () => {
  const userState = useReactiveVar<UserState>(UserStateManagerState);

  const { user, loading, updateUser } = userState;

  return userState;
};
