import {
  Box,
  Button,
  type ButtonPropsColorOverrides,
  Modal,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';
import { CENTER } from '../../utils/CONSTS/PRESTYLES';
import EbayLogo from '../EbayLogo';
import { type ModalStateType } from '../../stateManagement/ModalStateManager';
import { ebayAuthUrl } from './TokenSateComponent';

interface WarningModalProps {
  visible: boolean;
  onClose: () => void;
  modalState: ModalStateType;
}

const WarningModal = ({ visible, onClose, modalState }: WarningModalProps) => {
  const { palette } = useTheme();
  return (
    <Modal
      open={visible}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        ...CENTER,
      }}
    >
      <Box
        sx={{
          backgroundColor: palette?.background?.default,
          width: { xs: '95%', sm: '70%', lg: '60%' },
          overflowY: 'scroll',
          ...CENTER,
          flexDirection: 'column',
        }}
        p={3}
        borderRadius={3}
      >
        <EbayLogo size={100} />
        <Typography variant="h5" fontWeight={'700'} mb={2} textAlign={'center'}>
          {modalState?.title}
        </Typography>
        <Typography
          variant="body1"
          fontWeight={'400'}
          mb={2}
          textAlign={'center'}
        >
          {modalState?.subtitle}
        </Typography>
        <Box>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              onClose();
            }}
            sx={{ mr: 2 }}
          >
            Close
          </Button>
          {modalState?.onAction && (
            <Button
              variant="contained"
              color={(modalState?.onActionColor as 'primary') ?? 'primary'}
              onClick={() => {
                modalState?.onAction && modalState?.onAction();
                onClose();
              }}
            >
              {modalState.onActionText}
            </Button>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default WarningModal;
