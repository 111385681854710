import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './App.css';
import {
  BrowserRouter,
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import reportWebVitals from './reportWebVitals';
import App from './App';
import theme from './theme';
import CssBaseline from '@mui/material/CssBaseline';
import UserProfileLanding from './pages/userProfile/UserProfileLanding';
import { ErrorBoundary } from './components/ErrorBoundry';

const router = createBrowserRouter([
  {
    path: '*',
    element: (
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    ),
    // Optionally, you can add loaders, actions, or children here
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(<RouterProvider router={router} />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
