import React, { useEffect, useMemo } from 'react';

import {
  useModalStateManager,
  type ModalStateType,
} from '../../stateManagement/ModalStateManager';
import {
  Box,
  Grid2,
  Modal,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { CENTER } from '../../utils/CONSTS/PRESTYLES';
import Loading from '../Loading';
import EbayLogo from '../EbayLogo';
import useAEProductDetailAndShipping from '../../utils/hooks/useAEProductDetailAndShipping';
import {
  Details,
  DetailsRounded,
  Info,
  LocalShippingOutlined,
  PriceChange,
  Warning,
  WarningAmber,
} from '@mui/icons-material';
import { calculateSellingPrice } from '../../utils/helpers/listingPriceCalculatore';
import { type IEbaySettings } from '../../utils/TYPES';

interface ReadyResponseModalProps {
  visible: boolean;
  onClose: () => void;
  modalState: ModalStateType;
}

const AEProductDetailModal: React.FC<ReadyResponseModalProps> = ({
  visible,
  onClose,
  modalState,
}) => {
  const { palette } = useTheme();
  const { getShippingCost, deliveryOptions, aeDetailProduct, loading } =
    useAEProductDetailAndShipping();

  console.log('aeDetailProduct', aeDetailProduct, deliveryOptions);

  useEffect(() => {
    if (modalState?.data?.productId) {
      getShippingCost(modalState?.data?.productId as string);
    }
  }, []);

  const ebaySettings: IEbaySettings = modalState?.data?.ebaySettings;

  const {
    totalListingPriceMin,
    totalListingPriceMax,
    totalListingProfitMin,
    totalListingProfitMax,
  } = useMemo(() => {
    // 1) Early return if missing data
    if (!aeDetailProduct) {
      return {
        totalListingPriceMin: 0,
        totalListingPriceMax: 0,
        totalListingProfitMin: 0,
        totalListingProfitMax: 0,
      };
    }

    // 2) Parse shipping cost (in your code, it's just .shipping_fee_cent)
    //    Confirm if you need /100 if fee is in cents.
    const shippingCost = deliveryOptions?.length
      ? Number(deliveryOptions?.[0]?.shipping_fee_cent)
      : 0;

    // 3) Collect all SKU prices
    const skuList =
      aeDetailProduct.ae_item_sku_info_dtos?.ae_item_sku_info_d_t_o ?? [];

    // Convert sku_price to number, filter out invalid or NaN
    const skuPrices = skuList
      .map((sku) => Number(sku.sku_price))
      .filter((price) => !isNaN(price));

    console.log('skuPricesskuPricesskuPrices', skuPrices);

    // 4) If no valid SKU prices, return zeros
    if (!skuPrices.length) {
      return {
        totalListingPriceMin: 0,
        totalListingPriceMax: 0,
        totalListingProfitMin: 0,
        totalListingProfitMax: 0,
      };
    }

    // 5) Find min and max purchase price
    const minSkuPrice = Math.min(...skuPrices);
    const maxSkuPrice = Math.max(...skuPrices);

    // 6) Calculate fees from ebaySettings (fallback to 0 if undefined)
    const profitPercent = ebaySettings?.fees?.profitPercentage ?? 100;
    const ebayFeePercent = ebaySettings?.fees?.platformFeePercentage ?? 13;
    const ebayFixedFee = ebaySettings?.fees?.fixedFees ?? 0;

    // 7) Calculate the min price scenario
    const { totalPrice: totalPriceMin, totalProfit: totalProfitMin } =
      calculateSellingPrice({
        purchasePrice: minSkuPrice,
        shippingPrice: shippingCost,
        profitPercent,
        ebayFeePercent,
        ebayFixedFee,
      });

    // 8) Calculate the max price scenario
    const { totalPrice: totalPriceMax, totalProfit: totalProfitMax } =
      calculateSellingPrice({
        purchasePrice: maxSkuPrice,
        shippingPrice: shippingCost,
        profitPercent,
        ebayFeePercent,
        ebayFixedFee,
      });

    return {
      totalListingPriceMin: totalPriceMin,
      totalListingPriceMax: totalPriceMax,
      totalListingProfitMin: totalProfitMin,
      totalListingProfitMax: totalProfitMax,
    };
  }, [aeDetailProduct, deliveryOptions, ebaySettings]);
  return (
    <Modal
      open={visible}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        ...CENTER,
      }}
    >
      <Box
        sx={{
          backgroundColor: palette?.background?.default,
          width: { xs: '95%', sm: '80%', lg: '70%' },
          minHeight: 500,
          flexDirection: 'column',
          boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
          position: 'relative',
          border: `1px solid ${palette.divider}`,
        }}
        p={4}
        borderRadius={2}
      >
        {loading ? (
          <Box
            sx={{
              ...CENTER,
              height: 500,
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <Loading size={60} />
            <Typography variant="body1" color="text.secondary">
              Gathering information from Aliexpress...
            </Typography>
          </Box>
        ) : (
          <>
            {!aeDetailProduct ? (
              <Box sx={{ ...CENTER, height: 500 }}>
                <Typography
                  variant="h6"
                  fontWeight={600}
                  color="error.main"
                  sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                >
                  <Warning /> Product Not Found or Currently Unavailable
                </Typography>
              </Box>
            ) : (
              <Box>
                <Grid2 container spacing={3}>
                  <Grid2 size={2}>
                    <Box
                      component="img"
                      src={
                        aeDetailProduct?.ae_multimedia_info_dto?.image_urls?.split(
                          ';',
                        )?.[0]
                      }
                      alt="product"
                      sx={{
                        width: 160,
                        height: 160,
                        objectFit: 'cover',
                        borderRadius: 2,
                        border: `1px solid ${palette.divider}`,
                      }}
                    />
                  </Grid2>
                  <Grid2 size={7}>
                    <Typography
                      variant="h6"
                      fontWeight={600}
                      mb={2}
                      color="text.primary"
                    >
                      {aeDetailProduct?.ae_item_base_info_dto?.subject}
                    </Typography>
                    <Typography variant="subtitle2" color="primary.main" mb={1}>
                      Variations (
                      {
                        aeDetailProduct?.ae_item_sku_info_dtos
                          ?.ae_item_sku_info_d_t_o?.length
                      }
                      )
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        gap: 1,
                        width: '100%',
                        overflowX: 'auto',
                        pb: 1,
                        '&::-webkit-scrollbar': {
                          height: '6px',
                        },
                        '&::-webkit-scrollbar-track': {
                          background: palette.action.hover,
                          borderRadius: 3,
                        },
                        '&::-webkit-scrollbar-thumb': {
                          background: palette.primary.main,
                          borderRadius: 3,
                        },
                      }}
                    >
                      {aeDetailProduct?.ae_item_sku_info_dtos?.ae_item_sku_info_d_t_o?.map(
                        (item, index) => (
                          <Box
                            key={index}
                            component="img"
                            src={
                              item?.ae_sku_property_dtos
                                ?.ae_sku_property_d_t_o?.[0]?.sku_image
                            }
                            alt={`variation-${index}`}
                            sx={{
                              width: 60,
                              height: 60,
                              borderRadius: 1,
                              border: `1px solid ${palette.divider}`,
                              transition: 'transform 0.2s',
                              cursor: 'pointer',
                              '&:hover': {
                                transform: 'scale(1.05)',
                              },
                            }}
                          />
                        ),
                      )}
                    </Box>
                  </Grid2>
                  <Grid2 size={3}>
                    <Box
                      sx={{
                        p: 2,
                        bgcolor: palette.action.hover,
                        borderRadius: 2,
                        border: `1px solid ${palette.divider}`,
                      }}
                    >
                      {totalListingPriceMax ? (
                        <>
                          <Tooltip title="You can adjust your profit percentage in settings">
                            <Typography
                              variant="caption"
                              color="text.secondary"
                              sx={{
                                display: 'flex',
                                gap: 0.5,
                                mb: 1,
                              }}
                            >
                              <Info color="primary" sx={{ fontSize: 16 }} />
                              Potential Listing Price after platform fees,
                              shipping cost and
                              {ebaySettings?.fees?.profitPercentage ?? 100}%
                              margin
                            </Typography>
                          </Tooltip>
                          <Typography
                            variant="h5"
                            color="primary.main"
                            fontWeight={600}
                          >
                            ${totalListingPriceMin}
                            {totalListingPriceMax &&
                              ` - $${totalListingPriceMax}`}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            color="success.main"
                            sx={{ mt: 1 }}
                          >
                            Profit: ${totalListingProfitMin}
                            {totalListingProfitMax &&
                              ` - $${totalListingProfitMax}`}
                          </Typography>
                        </>
                      ) : (
                        <Typography
                          variant="subtitle1"
                          color="error.main"
                          sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                        >
                          <Warning /> Product Unavailable
                        </Typography>
                      )}
                    </Box>
                  </Grid2>
                </Grid2>

                <Grid2 container spacing={3} mt={2}>
                  {[
                    {
                      title: 'Specifications',
                      icon: <DetailsRounded />,
                      content: (
                        <Box
                          sx={{
                            maxHeight: 300,
                            overflowY: 'auto',
                            pr: 2,
                            '&::-webkit-scrollbar': {
                              width: '6px',
                            },
                            '&::-webkit-scrollbar-track': {
                              background: palette.action.hover,
                              borderRadius: 3,
                            },
                            '&::-webkit-scrollbar-thumb': {
                              background: palette.primary.main,
                              borderRadius: 3,
                            },
                          }}
                        >
                          {aeDetailProduct?.ae_item_properties?.ae_item_property?.map(
                            (item, index) => (
                              <Box
                                key={index}
                                sx={{
                                  mb: 1,
                                  p: 1.5,
                                  bgcolor: palette.action.hover,
                                  borderRadius: 1,
                                  '&:hover': {
                                    bgcolor: palette.action.selected,
                                  },
                                }}
                              >
                                <Typography
                                  variant="subtitle2"
                                  color="text.secondary"
                                >
                                  {item?.attr_name}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color="text.primary"
                                >
                                  {item?.attr_value}
                                </Typography>
                              </Box>
                            ),
                          )}
                        </Box>
                      ),
                    },
                    {
                      title: 'Shipping',
                      icon: <LocalShippingOutlined />,
                      content: !deliveryOptions?.length ? (
                        <Box
                          sx={{
                            p: 2,
                            bgcolor: palette.action.hover,
                            borderRadius: 1,
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                          }}
                        >
                          <WarningAmber color="warning" />
                          <Typography color="text.secondary">
                            Shipping information unavailable
                          </Typography>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1.5,
                          }}
                        >
                          {[
                            [
                              'Free Shipping',
                              deliveryOptions?.[0]?.free_shipping
                                ? 'Yes'
                                : 'No',
                            ],
                            [
                              'Shipping Fee',
                              `${deliveryOptions?.[0]?.shipping_fee_cent} ${deliveryOptions?.[0]?.shipping_fee_currency}`,
                            ],
                            [
                              'Delivery Time',
                              `${deliveryOptions?.[0]?.min_delivery_days} - ${deliveryOptions?.[0]?.max_delivery_days} days`,
                            ],
                            [
                              'Tracking Available',
                              deliveryOptions?.[0]?.tracking ? 'Yes' : 'No',
                            ],
                            [
                              'Delivery Date',
                              deliveryOptions?.[0]?.delivery_date_desc,
                            ],
                            ['Shipping Company', deliveryOptions?.[0]?.company],
                            [
                              'Ships From',
                              deliveryOptions?.[0]?.ship_from_country,
                            ],
                          ].map(([label, value]) => (
                            <Box
                              key={label}
                              sx={{
                                p: 1.5,
                                bgcolor: palette.action.hover,
                                borderRadius: 1,
                                '&:hover': {
                                  bgcolor: palette.action.selected,
                                },
                              }}
                            >
                              <Typography
                                variant="subtitle2"
                                color="text.secondary"
                              >
                                {label}
                              </Typography>
                              <Typography variant="body2" color="text.primary">
                                {value}
                              </Typography>
                            </Box>
                          ))}
                        </Box>
                      ),
                    },
                    {
                      title: 'Price Breakdown',
                      icon: <PriceChange />,
                      content: (
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1.5,
                          }}
                        >
                          {[
                            [
                              'Source Price',
                              `$${aeDetailProduct?.ae_item_sku_info_dtos?.ae_item_sku_info_d_t_o?.[0]?.sku_price}`,
                            ],
                            [
                              'Shipping Cost',
                              `${deliveryOptions?.[0]?.shipping_fee_cent} ${deliveryOptions?.[0]?.shipping_fee_currency}`,
                            ],
                            [
                              'Listing Price',
                              `$${totalListingPriceMin} - $${totalListingPriceMax}`,
                            ],
                            [
                              'Profit',
                              `$${totalListingProfitMin} - $${totalListingProfitMax}`,
                            ],
                            [
                              'Profit Margin',
                              `${ebaySettings?.fees?.profitPercentage}%`,
                            ],
                          ].map(([label, value]) => (
                            <Box
                              key={label}
                              sx={{
                                p: 1.5,
                                bgcolor: palette.action.hover,
                                borderRadius: 1,
                                '&:hover': {
                                  bgcolor: palette.action.selected,
                                },
                              }}
                            >
                              <Typography
                                variant="subtitle2"
                                color="text.secondary"
                              >
                                {label}
                              </Typography>
                              <Typography
                                variant="body2"
                                color="text.primary"
                                fontWeight={600}
                              >
                                {value}
                              </Typography>
                            </Box>
                          ))}
                        </Box>
                      ),
                    },
                  ].map(({ title, icon, content }) => (
                    <Grid2 key={title} size={4}>
                      <Box sx={{ height: '100%' }}>
                        <Typography
                          variant="h6"
                          fontWeight={600}
                          color="primary.main"
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            mb: 2,
                          }}
                        >
                          {icon} {title}
                        </Typography>
                        {content}
                      </Box>
                    </Grid2>
                  ))}
                </Grid2>
              </Box>
            )}
          </>
        )}
      </Box>
    </Modal>
  );
};

export default AEProductDetailModal;
