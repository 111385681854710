/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';

import { type User, getAuth } from 'firebase/auth';
import {
  type DocumentData,
  doc,
  getDoc,
  getFirestore,
  setDoc,
} from 'firebase/firestore';

import useIsLoggedIn from './useIsLoggedin';
import {
  type userRoleType,
  type UserState,
  UserStateManagerState,
} from '../../stateManagement/userStateManager';
import { SUBSCRIPTION_PRODUCTS } from '../CONSTS/SUBSCRIPTION_PRODUCTS';
import { flatten, keys, values } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { SelectedOrganisationAccountState } from '../../stateManagement/SelectedOrganisationAccount';

const useSyncUser = () => {
  const auth = getAuth();
  const database = getFirestore();
  const isLoggedIn = useIsLoggedIn();

  const navigation = useNavigate();

  const selectedOrganisation = useReactiveVar(SelectedOrganisationAccountState);

  console.log('selectedOrganisation', selectedOrganisation);

  const getUserAndPermission = async (): Promise<void> => {
    UserStateManagerState({ ...UserStateManagerState(), loading: true });
    if (auth?.currentUser?.uid && selectedOrganisation?.id) {
      // const perm = await auth.currentUser?.getIdTokenResult(true);
      // if (!perm?.claims?.hasAccess) navigation('/');

      const organisationAccessRef = doc(
        database,
        `organisations/${selectedOrganisation?.id}/access/stripe`,
      );

      const userRole = doc(
        database,
        `users/${auth.currentUser?.uid}/linkedOrganisation/${selectedOrganisation?.id}`,
      );

      const _organisationAccessSnap = await getDoc(organisationAccessRef);
      const _userRoleSnap = (await getDoc(userRole)).data() as DocumentData;
      const userRoleData = _userRoleSnap?.role as string;

      const organisationAccess =
        _organisationAccessSnap.data() as unknown as UserState['permissionStatus'];

      const subscribedProduct = flatten(
        values(SUBSCRIPTION_PRODUCTS).map((item) => values(item)),
      ).find((product) => product?.id === organisationAccess?.priceId);

      UserStateManagerState({
        ...UserStateManagerState(),
        loading: false,
        permissionStatus: {
          hasAccess: !!organisationAccess?.hasAccess,
          productId: organisationAccess?.productId as string,
          priceId: organisationAccess?.priceId as string,
          subscribedProduct,
          role: userRoleData as userRoleType,
        },
      });
    }
  };

  const updateUser = async (data: any) => {
    if (!auth.currentUser?.uid) return;
    try {
      const userRef = doc(database, 'users', auth.currentUser?.uid);
      await setDoc(userRef, data, { merge: true });
    } catch (error) {
      console.error('Error signing in with email and password', error);
    }
  };

  React.useEffect(() => {
    getUserAndPermission();
    UserStateManagerState({
      ...UserStateManagerState(),
      updateUser,
    });
  }, [isLoggedIn, selectedOrganisation]);
};

export default useSyncUser;
