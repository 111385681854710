import React, { useEffect } from 'react';
import CookieConsent from 'react-cookie-consent';
import Cookies from 'js-cookie';

const CookieBanner = () => {
  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept"
      declineButtonText="Decline"
      enableDeclineButton
      cookieName="userConsent"
      style={{ background: '#2B373B', color: '#fff' }}
      buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
      expires={365} // Expiry in days
    >
      We use cookies to improve your experience.{' '}
      <a
        href="/privacy-policy"
        style={{ color: '#fff', textDecoration: 'underline' }}
      >
        Learn more
      </a>
      .
    </CookieConsent>
  );
};

export default CookieBanner;
