import { Box, Button, Modal, Typography, useTheme } from '@mui/material';
import React from 'react';
import { CENTER } from '../../utils/CONSTS/PRESTYLES';
import EbayLogo from '../EbayLogo';
import { type ModalStateType } from '../../stateManagement/ModalStateManager';
import { AliExpressAuthUrl } from './TokenSateComponent';
import AliExpressLogo from '../AliExpressLogo';

interface ReadyResponseModalProps {
  visible: boolean;
  onClose: () => void;
  modalState: ModalStateType;
}

const AliExpressTokenModal = ({
  visible,
  onClose,
  modalState,
}: ReadyResponseModalProps) => {
  const { palette } = useTheme();
  return (
    <Modal
      open={visible}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        ...CENTER,
      }}
    >
      <Box
        sx={{
          backgroundColor: palette?.background?.default,
          width: { xs: '95%', sm: '70%', lg: '60%' },
          overflowY: 'scroll',
          ...CENTER,
          flexDirection: 'column',
        }}
        p={3}
        borderRadius={3}
      >
        <AliExpressLogo size={100} />
        <Typography variant="h5" fontWeight={'700'} mb={2}>
          ALI EXPRESS ACCOUNT IS NOT CONNECTED
        </Typography>
        <Typography
          variant="body1"
          fontWeight={'400'}
          mb={2}
          sx={{ textAlign: 'center' }}
        >
          {modalState?.subtitle ??
            'To Compare Products Please Connect Your AliExpress Account, or removeAliExpress checkbox from the filter'}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            window.open(AliExpressAuthUrl, '_blank');
          }}
        >
          Connect AliExpress
        </Button>
      </Box>
    </Modal>
  );
};

export default AliExpressTokenModal;
