/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';

import {
  ModalStateManager,
  useModalStateManager,
  type ModalStateType,
} from '../../stateManagement/ModalStateManager';
import {
  Box,
  Button,
  Checkbox,
  FormLabel,
  Grid,
  MenuItem,
  Modal,
  Select,
  type SelectChangeEvent,
  Tab,
  Tabs,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { CENTER } from '../../utils/CONSTS/PRESTYLES';
import Loading from '../Loading';
import EbayLogo from '../EbayLogo';
import { capitalize, cloneDeep } from 'lodash';
import TabPanel from '../TabPanel';
import {
  AmazonProductType,
  type IEbayDraftListingVariations,
  type IEbayDraftListing,
} from '../../utils/TYPES';
import { calculateEbayProfitOrSellingPrice } from '../../utils/helpers/ebayProfitCalculator';
import { doc, getFirestore, setDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { LoadingButton } from '@mui/lab';
import { Add } from '@mui/icons-material';
import { EbayPolicyState } from '../../stateManagement/EbayPolicyState';
import { useReactiveVar } from '@apollo/client';
import { EbayInventoryLocationsState } from '../../stateManagement/EbayInventoryLocationsState';
import EditableAspects from '../EditableApects';
import EditableAspectsSingleLists from '../EditableAspectsSingleLists';
import { SelectedOrganisationAccountState } from '../../stateManagement/SelectedOrganisationAccount';

interface EbayPriceEditorModalProps {
  visible: boolean;
  onClose: () => void;
  modalState: ModalStateType;
}

const db = getFirestore();
const auth = getAuth();

const EbayPriceEditorModal: React.FC<EbayPriceEditorModalProps> = ({
  visible,
  onClose,
  modalState,
}) => {
  const { palette } = useTheme();
  const { data } = modalState;
  console.log('datadatadatadd', data);

  const variation: IEbayDraftListingVariations = data?.variation;
  const groupId = data?.id;
  const marketPlaceId = data?.marketPlaceId;
  function a11yProps(index: number) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }
  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const [calculateFromProfit, setCalculateFromProfit] = React.useState(false);
  const [profit, setProfit] = React.useState(0);
  const [formState, setFormState] = React.useState<IEbayDraftListing | null>(
    null,
  );
  const [loading, setLoading] = React.useState(false);

  const [, , resetModalState] = useModalStateManager();

  const handleChange = (e: any, path: string[]) => {
    const value = e.target.value;

    // Use a functional state update to ensure React properly updates the state
    setFormState((prevState: any) => {
      const updatedState = { ...prevState };

      let current: any = updatedState;
      for (let i = 0; i < path.length - 1; i++) {
        current = current[path[i]];
      }
      current[path[path.length - 1]] =
        e?.target?.type === 'number' ? Number(value) : value; // Always update the value correctly

      return updatedState; // Return the updated state
    });
  };

  const calcFinalSellingPrice = () => {
    if (
      !variation?.offer?.pricingSummary?.price?.value ||
      !formState?.offer?.pricingSummary?.price?.value
    )
      return;
    const _ebayProfitCalculated = calculateEbayProfitOrSellingPrice({
      profit: calculateFromProfit ? profit : null,
      purchasePrice: Number(
        variation?.originalProduct?.product_price ??
          variation?.originalProductPrice,
      ),
      sellingPrice: calculateFromProfit
        ? null
        : Number(formState?.offer?.pricingSummary?.price?.value || 0),
      shippingCost: Number(variation?.shippingFee || 0),
      isUK: false,
      isPrivateSeller: true,
      ebayFeePercentage: Number(formState?.fees?.platformFeePercentage) ?? 0,
      fixedPaypalFee: Number(formState?.fees?.fixedFees) ?? 0,
    });

    if (!calculateFromProfit) setProfit(Number(_ebayProfitCalculated?.profit));
    if (calculateFromProfit)
      handleChange({ target: { value: _ebayProfitCalculated?.totalCosts } }, [
        'offer',
        'pricingSummary',
        'price',
        'value',
      ]);
  };
  useEffect(() => {
    calcFinalSellingPrice();
  }, [
    formState?.offer?.pricingSummary?.price?.value,
    profit,
    formState?.fees?.platformFeePercentage,
    formState?.fees?.fixedFees,
  ]);

  useEffect(() => {}, [formState]);

  console.log('formState', formState);

  useEffect(() => {
    if (!data) return;

    setFormState(cloneDeep(variation));

    calcFinalSellingPrice();
  }, [data]);

  const priceValue = formState?.offer?.pricingSummary?.price?.value ?? 0;

  const onSave = async () => {
    setLoading(true);
    const variationRef = doc(
      db,
      `organisations/${SelectedOrganisationAccountState()?.id}/stores/${SelectedOrganisationAccountState()?.activeStoreId}/ebayListings/${groupId}/variations/${variation?.id}`,
    );
    await setDoc(variationRef, formState);
    setLoading(false);
    resetModalState();
  };

  const { fulfillmentPolicies, returnPolicies, paymentPolicies } =
    useReactiveVar(EbayPolicyState);

  const ebayInventoryLocations = useReactiveVar(EbayInventoryLocationsState);

  console.log('formStateformState 33', formState);

  if (!formState) return null;
  const { length, width, height, unit } =
    formState.product.packageWeightAndSize.dimensions;
  const { value: weightValue, unit: weightUnit } =
    formState.product.packageWeightAndSize.weight;

  return (
    <Modal
      open={visible}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        ...CENTER,
      }}
    >
      <Box
        sx={{
          backgroundColor: palette?.background?.default,
          width: { xs: '95%', sm: '80%', lg: '70%' },
          flexDirection: 'column',
          borderColor: 'primary.main',
          border: 1,
          boxShadow: 24,
        }}
        p={3}
        borderRadius={3}
      >
        <Typography variant="h5" fontWeight={'700'} mb={1}>
          Edit Variant
        </Typography>
        <Grid container spacing={2} mt={1} mb={1}>
          <Grid item xs={10}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={variation?.product?.product?.imageUrls[0]}
                alt="product"
                style={{
                  width: 150,
                  height: 150,
                  borderRadius: 10,
                  objectFit: 'contain',
                }}
              />
              <Box ml={2}>
                <Typography mb={1}>
                  {variation?.product?.product?.title}
                </Typography>
                <Box sx={{ display: 'flex' }}>
                  {variation?.specifications &&
                    typeof variation.specifications === 'object' &&
                    !Array.isArray(data.specifications) &&
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    Object.entries(
                      variation?.specifications as Record<string, unknown>,
                    ).map(([key, value], index) => (
                      <Typography
                        mr={1}
                        key={key}
                        gutterBottom
                        sx={{ opacity: 0.7 }}
                      >
                        {capitalize(key)}: {String(value)}
                        {Object.keys(
                          variation?.specifications as Record<
                            string,
                            // eslint-disable-next-line prettier/prettier
                        unknown
                          >,
                        ).length -
                          1 !==
                          index && ' |'}
                      </Typography>
                    ))}
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid
            item
            xs={2}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6" color="success">
              {priceValue} {variation?.offer?.pricingSummary?.price?.currency}{' '}
            </Typography>
          </Grid>
        </Grid>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="secondary"
          textColor="inherit"
          aria-label="full width tabs example"
        >
          <Tab
            label="Price"
            sx={{ color: palette?.primary?.main }}
            {...a11yProps(0)}
          />
          <Tab
            sx={{ color: palette?.primary?.main }}
            label="Settings"
            {...a11yProps(1)}
          />
          {/* <Tab
            sx={{ color: palette?.primary?.main }}
            label="Aspects"
            {...a11yProps(3)}
          /> */}
        </Tabs>
        <TabPanel value={String(tabValue)} index={0}>
          <Box>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Typography gutterBottom>
                    Calculate sale price from profit value
                  </Typography>
                  <Checkbox
                    checked={calculateFromProfit}
                    onChange={(e) => {
                      setCalculateFromProfit(e.target.checked);
                    }}
                  />
                </Box>
              </Box>
              {/* <Typography variant="h6" gutterBottom>
                {variation?.shippingFee}
              </Typography> */}
              <Grid container spacing={2} sx={{ alignItems: 'center', mb: 2 }}>
                <Grid item xs={4}>
                  <TextField
                    sx={{ backgroundColor: palette?.background?.default }}
                    label="Source Price"
                    variant="outlined"
                    fullWidth
                    value={`${(Number(variation?.originalProduct?.product_price ?? variation?.originalProductPrice) + Number(variation?.shippingFee || 0)).toFixed(2)} ${variation?.originalProduct?.currency ?? 'USD'}`}
                    disabled
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    sx={{ backgroundColor: palette?.background?.default }}
                    label="Platform Fee %"
                    fullWidth
                    type="number"
                    value={Number(
                      formState?.fees?.platformFeePercentage?.toFixed(2),
                    )}
                    margin="normal"
                    onChange={(e) => {
                      handleChange(e, ['fees', 'platformFee']);
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    sx={{ backgroundColor: palette?.background?.default }}
                    label="Fixed Fee"
                    type="number"
                    fullWidth
                    value={Number(formState?.fees?.fixedFees).toFixed(2)}
                    margin="normal"
                    onChange={(e) => {
                      handleChange(e, ['fees', 'fixedFees']);
                    }}
                    inputMode="decimal"
                    inputProps={{ min: 0, step: 0.01 }}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <Grid item xs={6}>
                  <TextField
                    sx={{ backgroundColor: palette?.background?.default }}
                    label={`Sale Price in ${variation?.originalProduct?.currency}`}
                    type="number"
                    disabled={calculateFromProfit}
                    variant="outlined"
                    fullWidth
                    value={priceValue || 0}
                    onChange={(e) => {
                      handleChange(e, [
                        'offer',
                        'pricingSummary',
                        'price',
                        'value',
                      ]);
                    }}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={4}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                      sx={{ backgroundColor: palette?.background?.default }}
                      label={
                        'Profit' +
                        ' in ' +
                        formState?.offer?.pricingSummary?.price?.currency
                      }
                      disabled={!calculateFromProfit}
                      type="number"
                      variant="outlined"
                      fullWidth
                      value={profit}
                      onChange={(e) => {
                        setProfit(Number(e.target.value));
                      }}
                      margin="normal"
                    />
                  </Box>
                </Grid>
                {/* <Grid item xs={6}>
                  <Select
                    value={currency}
                    onChange={(e) => {
                    }}
                    sx={{
                      backgroundColor: palette?.background?.default,
                    }}
                    fullWidth
                  >
                    <MenuItem value="USD">USD $</MenuItem>
                    <MenuItem value="GBP">British Pound £</MenuItem>
                  </Select>
                </Grid> */}
              </Grid>
            </Box>
          </Box>
        </TabPanel>
        <TabPanel value={String(tabValue)} index={1}>
          <Box>
            <Grid
              container
              spacing={2}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <Grid item xs={4}>
                <TextField
                  sx={{ backgroundColor: palette?.background?.default }}
                  label={'quantity'}
                  type="number"
                  variant="outlined"
                  fullWidth
                  inputProps={{ min: 0 }}
                  value={
                    formState?.product?.availability?.shipToLocationAvailability
                      ?.quantity
                  }
                  onChange={(e) => {
                    handleChange(e, [
                      'product',
                      'availability',
                      'shipToLocationAvailability',
                      'quantity',
                    ]);
                    handleChange(e, ['offer', 'availableQuantity']);

                    handleChange(
                      {
                        target: {
                          value:
                            Number(e.target.value) > 0
                              ? 'IN_STOCK'
                              : 'OUT_OF_STOCK',
                        },
                      },
                      ['product', 'availability', 'availabilityType'],
                    );
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  sx={{ backgroundColor: palette?.background?.default }}
                  label={'Availability Type'}
                  variant="outlined"
                  fullWidth
                  inputProps={{ min: 0 }}
                  value={formState?.product?.availability?.availabilityType}
                  disabled
                />
              </Grid>
            </Grid>
            <Box mt={3}>
              <Typography variant="h6" gutterBottom>
                PACKAGE WEIGHT AND SIZE (optional)
              </Typography>
              <FormLabel required sx={{ mt: 2 }}>
                Dimension
              </FormLabel>
              <Grid container spacing={2} mt={0.5}>
                <Grid item xs={3}>
                  <TextField
                    sx={{ backgroundColor: palette?.background?.default }}
                    label="Length"
                    type="number"
                    variant="outlined"
                    fullWidth
                    value={length}
                    onChange={(e) => {
                      handleChange(e, [
                        'product',
                        'packageWeightAndSize',
                        'dimensions',
                        'length',
                      ]);
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    sx={{ backgroundColor: palette?.background?.default }}
                    label="Width"
                    type="number"
                    variant="outlined"
                    fullWidth
                    value={width}
                    onChange={(e) => {
                      handleChange(e, [
                        'product',
                        'packageWeightAndSize',
                        'dimensions',
                        'width',
                      ]);
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    sx={{ backgroundColor: palette?.background?.default }}
                    label="Height"
                    type="number"
                    variant="outlined"
                    fullWidth
                    value={height}
                    onChange={(e) => {
                      handleChange(e, [
                        'product',
                        'packageWeightAndSize',
                        'dimensions',
                        'height',
                      ]);
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Select
                    value={unit}
                    onChange={(e) => {
                      handleChange(e, [
                        'product',
                        'packageWeightAndSize',
                        'dimensions',
                        'unit',
                      ]);
                    }}
                    sx={{
                      backgroundColor: palette?.background?.default,
                    }}
                    fullWidth
                  >
                    <MenuItem value="INCH">Inch</MenuItem>
                    <MenuItem value="FEET">Feet</MenuItem>
                    <MenuItem value="CENTIMETER">Centimeter</MenuItem>
                    <MenuItem value="METER">Meter</MenuItem>
                  </Select>
                </Grid>
              </Grid>
              <Box mt={2}>
                <FormLabel required>Weight</FormLabel>
              </Box>
              <Grid container spacing={2} mt={0.5}>
                <Grid item xs={6}>
                  <TextField
                    sx={{ backgroundColor: palette?.background?.default }}
                    label="Value"
                    type="number"
                    variant="outlined"
                    fullWidth
                    value={weightValue}
                    onChange={(e) => {
                      handleChange(e, [
                        'product',
                        'packageWeightAndSize',
                        'weight',
                        'value',
                      ]);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Select
                    value={weightUnit}
                    onChange={(e) => {
                      handleChange(e, [
                        'product',
                        'packageWeightAndSize',
                        'weight',
                        'unit',
                      ]);
                    }}
                    sx={{
                      backgroundColor: palette?.background?.default,
                    }}
                    fullWidth
                  >
                    <MenuItem value="OUNCE">Ounce</MenuItem>
                    <MenuItem value="POUND">Pound</MenuItem>
                    <MenuItem value="GRAM">Gram</MenuItem>
                    <MenuItem value="KILOGRAM">Kilogram</MenuItem>
                  </Select>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </TabPanel>
        {/* <TabPanel value={String(tabValue)} index={2}>
          <Box>
            <Typography variant="h6" gutterBottom>
              BUSINESS POLICIES
            </Typography>
            <Grid container mt={2}>
              <Grid item xs={12}>
                <FormLabel required>Return Policy</FormLabel>
                <Grid container spacing={2} sx={{ alignItems: 'center' }}>
                  <Grid item xs={10}>
                    <Select
                      value={formState?.offer?.listingPolicies?.returnPolicyId}
                      onChange={(e: SelectChangeEvent) => {
                        handleChange(e, [
                          'offer',
                          'listingPolicies',
                          'returnPolicyId',
                        ]);
                      }}
                      sx={{
                        backgroundColor: palette?.background?.default,
                      }}
                      fullWidth
                    >
                      {!!returnPolicies?.length &&
                        returnPolicies.map((policy) => {
                          return (
                            <MenuItem
                              key={policy?.returnPolicyId}
                              value={policy?.returnPolicyId}
                            >
                              {policy?.name} (
                              {policy?.returnsAccepted && policy?.returnMethod}
                              {', '}
                              {policy?.returnsAccepted &&
                                policy?.returnPeriod?.value}{' '}
                              {policy?.returnsAccepted &&
                                policy?.returnPeriod?.unit}
                              )
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      onClick={() => {
                        ModalStateManager({
                          type: 'RETURN_POLICY',
                        });
                      }}
                      startIcon={<Add />}
                    >
                      Return Policy
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} mt={2}>
                <FormLabel required>Fulfillment Policy</FormLabel>
                <Grid container spacing={2} sx={{ alignItems: 'center' }}>
                  <Grid item xs={10}>
                    <Select
                      value={
                        formState?.offer?.listingPolicies?.fulfillmentPolicyId
                      }
                      onChange={(e: SelectChangeEvent) => {
                        // createFulfillmentPolicy(e.target.value as string);
                        handleChange(e, [
                          'offer',
                          'listingPolicies',
                          'fulfillmentPolicyId',
                        ]);
                      }}
                      sx={{
                        backgroundColor: palette?.background?.default,
                      }}
                      fullWidth
                    >
                      {!!fulfillmentPolicies?.length &&
                        fulfillmentPolicies.map((policy) => (
                          <MenuItem
                            key={policy?.fulfillmentPolicyId}
                            value={policy?.fulfillmentPolicyId}
                          >
                            {policy?.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      onClick={() => {
                        ModalStateManager({
                          type: 'FULFILLMENT_POLICY',
                          data: { marketplaceId: marketPlaceId },
                        });
                      }}
                      startIcon={<Add />}
                    >
                      Fulfillment Policy
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} mt={2}>
                <FormLabel required>Payment Policy</FormLabel>
                <Grid container spacing={2} sx={{ alignItems: 'center' }}>
                  <Grid item xs={10}>
                    <Select
                      value={formState?.offer?.listingPolicies?.paymentPolicyId}
                      onChange={(e: SelectChangeEvent) => {
                        handleChange(e, [
                          'offer',
                          'listingPolicies',
                          'paymentPolicyId',
                        ]);
                      }}
                      sx={{
                        backgroundColor: palette?.background?.default,
                      }}
                      fullWidth
                    >
                      {!!paymentPolicies?.length &&
                        paymentPolicies.map((policy) => (
                          <MenuItem
                            key={policy?.paymentPolicyId}
                            value={policy?.paymentPolicyId}
                          >
                            {policy?.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      onClick={() => {
                        ModalStateManager({
                          type: 'PAYMENT_POLICY',
                        });
                      }}
                      startIcon={<Add />}
                    >
                      Payment Policy
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={0.5} sx={{ alignItems: 'center' }}>
              <Grid item xs={10}>
                <Select
                  value={formState?.offer?.merchantLocationKey}
                  onChange={(e) => {
                    handleChange(e, ['offer', 'merchantLocationKey']);
                  }}
                  fullWidth
                  sx={{
                    backgroundColor: palette?.background?.default,
                  }}
                  required
                >
                  {!!ebayInventoryLocations?.length &&
                    ebayInventoryLocations.map((location) => (
                      <MenuItem
                        key={location?.merchantLocationKey}
                        value={location?.merchantLocationKey}
                      >
                        {location?.merchantLocationKey} (
                        {location?.location?.address?.city}
                        {' - '}
                        {location?.location?.address?.country}
                        {' - '}
                        {location?.location?.address?.postalCode})
                      </MenuItem>
                    ))}
                </Select>
              </Grid>
              <Grid item xs={2}>
                <Button
                  onClick={() => {
                    ModalStateManager({
                      type: 'INVENTORY_LOCATION',
                    });
                  }}
                  startIcon={<Add />}
                >
                  Add Location
                </Button>
              </Grid>
            </Grid>
          </Box>
        </TabPanel> */}
        {/* <TabPanel value={String(tabValue)} index={3}>
          <EditableAspectsSingleLists
            aspects={formState.product.product.aspects}
            setFormState={setFormState}
          />
        </TabPanel> */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mt: 2,
          }}
        >
          <Button
            variant="outlined"
            color="error"
            sx={{ mr: 2 }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            onClick={() => {
              onSave();
            }}
            loading={loading}
          >
            Save
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default EbayPriceEditorModal;
