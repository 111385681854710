import { createTheme } from '@mui/material/styles';
import { grey, red, common, deepOrange } from '@mui/material/colors';
import { useReactiveVar } from '@apollo/client';
import { useDarkModeState } from './stateManagement/DarkModeStateManagement';
import COLORS from './utils/CONSTS/COLORS';

declare module '@mui/material/styles' {
  // eslint-disable-next-line prettier/prettier
  interface palette {
    surface?: {
      main: string,
      dark: string,
    };
    customBackground: {
      light: string,
      main: string,
      dark: string,
      contrastText: string,
    };
  }
  interface PaletteOptions {
    surface?: {
      main?: string,
      dark?: string,
    };
    customBackground: {
      light: string,
      main: string,
      dark: string,
      contrastText: string,
    };
  }
}

const useThemeProvider = () => {
  const [darkModeState] = useDarkModeState();
  // A custom theme for this app
  const theme = createTheme({
    palette: {
      mode: darkModeState ? 'dark' : 'light',
      ...(!darkModeState
        ? {
            primary: {
              main: '#0842a0',
              dark: '#13144e',
              light: '#d8d8f1',
            },
            text: {
              primary: '#4d4d4d',
              secondary: '#000000',
            },
            secondary: {
              main: '#ffbc42',
            },
            background: {
              default: '#f3f3f3',
              paper: '#ffffff',
            },
            customBackground: {
              dark: '#ffffff',
              main: '#e9e6e7',
              light: '#f3f3f3',
              contrastText: '#000000',
            },
            surface: {
              main: '#f3f7fd',
              dark: '#ffffff',
            },
            error: {
              main: COLORS.ACCENT_DARK,
            },
          }
        : {
            primary: {
              main: '#4488f9',
              dark: '#13144e',
              light: '#d8d8f1',
              contrastText: common.white,
            },
            secondary: {
              main: '#ffbc42',
            },
            text: {
              primary: '#e7e7e7',
              secondary: '#ffffff',
            },
            surface: {
              main: '#192a38',
              dark: '#072443',
            },
            background: {
              default: '#031525',
              paper: '#062443',
            },
            customBackground: {
              dark: '#121212',
              main: '#212121',
              light: '#f3f3f3',
              contrastText: '#000000',
            },
            error: {
              main: COLORS.ACCENT_DARK,
            },
          }),
    },
  });

  return theme;
};

export default useThemeProvider;
