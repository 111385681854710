/* eslint-disable @typescript-eslint/no-explicit-any */
import { useReactiveVar } from '@apollo/client';
import { getFunctions, httpsCallableFromURL } from 'firebase/functions';
import { firebaseCall } from '../utils/CONSTS/ENDPOINTS';
import { AuthTokenState } from './AuthTokenState';

export const AE_TOKEN = 'AE_TOKEN';
export const EBAY_TOKEN = 'EBAY_TOKEN';
export const EBAY_AUTH_TOKEN = 'EBAY_AUTH_TOKEN';

const functions = getFunctions();
export const useTokenValidation = () => {
  const authState = useReactiveVar(AuthTokenState);

  const validateUserToken = async (
    tokenType: 'AE_TOKEN' | 'EBAY_AUTH_TOKEN',
  ) => {
    const validate = httpsCallableFromURL(
      functions,
      firebaseCall('tokenValidation'),
    );

    const response = (await validate({ type: tokenType })) as {
      data: { message?: string, status?: number },
    };
    console.log('response token', response, tokenType);

    if (tokenType === AE_TOKEN && response?.data?.status === 400) {
      console.log('response token INSIDE', tokenType);

      return true;
    } else if (
      tokenType === AE_TOKEN &&
      response?.data?.message === 'success'
    ) {
      return false;
    }

    if (tokenType === EBAY_AUTH_TOKEN && response?.data?.status === 400) {
      return true;
    } else if (
      tokenType === EBAY_AUTH_TOKEN &&
      response?.data?.message === 'success'
    ) {
      return false;
    }

    return true;
  };

  const validate = async () => {
    const aeTokenResponse = await validateUserToken(AE_TOKEN);
    const ebayTokenREsponse = await validateUserToken(EBAY_AUTH_TOKEN);

    console.log('aeTokenResponse', ebayTokenREsponse);

    return AuthTokenState({
      AE_TOKEN: aeTokenResponse,
      EBAY_AUTH_TOKEN: ebayTokenREsponse,
    });
  };

  return validate;
};
