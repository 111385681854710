import { getFunctions, httpsCallableFromURL } from 'firebase/functions';
import React, { useEffect, useState } from 'react';
import { type AmazonProductType } from '../TYPES';
import { firebaseCall } from '../CONSTS/ENDPOINTS';
import { type AmazonAIBestSellerDiscovererType } from './useAmazonAIBestSellerDiscoverer';
import { useNavigate } from 'react-router-dom';
import {
  ModalStateManager,
  useModalStateManager,
} from '../../stateManagement/ModalStateManager';
import EbayLogo from '../../components/EbayLogo'; // Adjust the import path as necessary
import {
  EbayInventoryLocationsState,
  type IEbayInventoryLocation,
} from '../../stateManagement/EbayInventoryLocationsState';
import {
  EbayPolicyState,
  type IEbayPolicy,
} from '../../stateManagement/EbayPolicyState';
import { doc, getFirestore, updateDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { ActiveMarketPlace } from '../../stateManagement/ActiveMarketPlace';
import { SelectedOrganisationAccountState } from '../../stateManagement/SelectedOrganisationAccount';
import { useReactiveVar } from '@apollo/client';
import { Snackbar, Alert, Link } from '@mui/material';
import { isSandbox } from '../../env';

const functions = getFunctions();
const db = getFirestore();
const auth = getAuth();
// B07NRS8N8M
const useEbayInventoryItem = () => {
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [listingUrl, setListingUrl] = useState('');
  const Navigate = useNavigate();
  const activeMarketPlace = useReactiveVar(ActiveMarketPlace);

  const [, updateModal] = useModalStateManager();

  const listInventoryItemOnEbay = async (itemGroupId: string) => {
    try {
      setLoading(true);
      // remove the error message
      const ref = doc(
        db,
        `organisations/${SelectedOrganisationAccountState()?.id}/stores/${SelectedOrganisationAccountState()?.activeStoreId}/ebayListings/${itemGroupId}`,
      );
      await updateDoc(ref, { errors: [] });

      ModalStateManager({
        title: 'please wait while Drop Compare list your product on eBay',
        type: 'OPERATION',
        data: {
          logo: 'ebay',
        },
      });

      console.log('listing product on ebay');

      const listProduct = httpsCallableFromURL(
        functions,
        firebaseCall('createUpdateInventoryItemGroupOnEbay'),
      );
      interface EbayListingResponse {
        data: {
          listingId?: string,
          error?: {
            errors: Array<{ message: string }>,
          },
        };
      }
      const response = (await listProduct({
        itemGroupId,
        region: activeMarketPlace,
      })) as EbayListingResponse;

      if (
        (response?.data as { error: { errors: Array<{ message: string }> } })
          ?.error?.errors?.length
      ) {
        updateModal({
          type: 'WARNING',
          subtitle: (
            response?.data as { error: { errors: Array<{ message: string }> } }
          )?.error?.errors[0]?.message,
        });
        return;
      }

      const ebayUrl = `https://${isSandbox ? 'sandbox.ebay.com' : activeMarketPlace === 'GB' ? 'www.ebay.co.uk' : 'www.ebay.com'}/itm/${response?.data?.listingId}`;
      setListingUrl(ebayUrl);
      setToastMessage('eBay listing was successful!');
      setShowToast(true);

      updateModal({
        type: null,
      });

      setLoading(false);
    } catch (error) {
      console.log('Error listing product on ebay', error);
      setLoading(false);
    }
  };

  const createDraftInventoryItemForEbay = async (
    productId: string,
    market: 'amazon' | 'aliexpress',
    region = 'US',
  ) => {
    try {
      setLoading(true);
      updateModal({
        title: 'please wait while Drop Compare create your draft eBay listing',
        type: 'OPERATION',
        data: {
          logo: 'ebay',
        },
      });

      console.log('listing product on ebay');

      const createDraft = httpsCallableFromURL(
        functions,
        firebaseCall('createDraftEbayListing'),
      );
      const response = await createDraft({
        productId,
        market,
        region,
      });

      console.log('response', response);

      const draftId = (response?.data as { itemId: string })?.itemId;
      updateModal({
        type: null,
      });

      //   Navigate(`/ebay-listings/${draftId}`);
    } catch (error) {
      console.error('Error listing product on ebay', error);
      setLoading(false);
    }
  };

  const getEbayInventoryItem = async (sku: string) => {
    try {
      setLoading(true);
      const getItem = httpsCallableFromURL(
        functions,
        firebaseCall('getInventoryItemFromEbay'),
      );
      const response = await getItem({
        sku,
      });

      console.log('response ITEM', response);

      setLoading(false);
      return response;
    } catch (error) {
      console.error('Error getting ebay inventory item', error);
      setLoading(false);
    }
  };

  const getEbayInventoryItems = async (limit = 30, offset = 0) => {
    try {
      setLoading(true);
      const getItems = httpsCallableFromURL(
        functions,
        firebaseCall('getInventoryItemsFromEbay'),
      );
      const response = await getItems({
        limit,
        offset,
      });

      console.log('responses', response);

      setLoading(false);
      return response;
    } catch (error) {
      console.error('Error getting ebay inventory item', error);
      setLoading(false);
    }
  };

  const createInventoryLocation = async (
    location: IEbayInventoryLocation,
    merchantLocationKey: string,
  ) => {
    try {
      setLoading(true);
      ModalStateManager({
        ...ModalStateManager(),
        loading: true,
      });
      const createLocation = httpsCallableFromURL(
        functions,
        firebaseCall('createInventoryLocation'),
      );
      const response = await createLocation({
        merchantLocationKey,
        location,
      });

      await getInventoryLocations();

      ModalStateManager({
        type: null,
        loading: false,
      });
      setLoading(false);
    } catch (error) {
      console.error('Error creating inventory location', error);
      ModalStateManager({
        ...ModalStateManager(),
        loading: false,
      });
    }
  };

  const getInventoryLocations = async () => {
    setLoading(true);
    const getLocations = httpsCallableFromURL(
      functions,
      firebaseCall('getInventoryLocations'),
    );
    const response = await getLocations();

    // const filterLocationCountry = (
    //   response?.data as { locations: IEbayInventoryLocation[] }
    // )?.locations.filter(
    //   (location: IEbayInventoryLocation) =>
    //     location.location.address?.country === activeMarketPlace,
    // );

    EbayInventoryLocationsState(
      (response?.data as { locations: IEbayInventoryLocation[] })?.locations,
    );

    setLoading(false);
  };

  const createReturnPolicy = async (
    policy: IEbayPolicy['returnPolicies'][0],
  ) => {
    try {
      updateModal({
        loading: true,
        error: null,
      });
      setLoading(true);
      const _createReturnPolicy = httpsCallableFromURL(
        functions,
        firebaseCall('createReturnPolicy'),
      );
      const response = await _createReturnPolicy({
        policy,
      });

      console.log('response createReturnPolicy', response?.data);

      setLoading(false);
      await getReturnPolicy(policy.marketplaceId);
      if ((response?.data as { status: number })?.status === 400) {
        updateModal({
          loading: false,
          error: (response?.data as { message: string })?.message,
        });
      } else {
        updateModal({
          loading: false,
          type: null,
        });
      }
    } catch (error) {
      console.error('Error createReturnPolicy', error);
      setLoading(false);
      updateModal({
        loading: false,
      });
      updateModal({
        loading: false,
        error: 'Something went wrong, Please try again in a bit',
      });
    }
  };

  const getReturnPolicy = async (marketPlaceId: string) => {
    if (!marketPlaceId) return;
    try {
      setLoading(true);
      const _createReturnPolicy = httpsCallableFromURL(
        functions,
        firebaseCall('getReturnPolicies'),
      );
      const response = await _createReturnPolicy({
        marketPlaceId,
      });

      console.log('response getReturnPolicy', response?.data);

      EbayPolicyState({
        ...EbayPolicyState(),
        returnPolicies: (
          response?.data as {
            returnPolicies: IEbayPolicy['returnPolicies'],
          }
        )?.returnPolicies,
      });

      setLoading(false);
    } catch (error) {
      console.error('Error createReturnPolicy', error);
      setLoading(false);
    }
  };

  const createFulfillmentPolicy = async (
    policy: IEbayPolicy['fulfillmentPolicies'][0],
  ) => {
    try {
      updateModal({
        loading: true,
        error: null,
      });
      setLoading(true);
      const _createFulfillmentPolicy = httpsCallableFromURL(
        functions,
        firebaseCall('createFulfillmentPolicy'),
      );
      const response = await _createFulfillmentPolicy({
        policy,
      });

      console.log('response', response?.data);

      if ((response?.data as { status: number })?.status === 400) {
        updateModal({
          loading: false,
          error: (response?.data as { message: string })?.message,
        });
      } else {
        await getFulfillmentPolicy(policy.marketplaceId);
        updateModal({
          loading: false,
          type: null,
        });
      }

      setLoading(false);
    } catch (error) {
      console.error('Error createReturnPolicy', error);
      setLoading(false);
      updateModal({
        loading: false,
        error: 'Something went wrong, Please try again in a bit',
      });
    }
  };

  const getFulfillmentPolicy = async (marketPlaceId: string) => {
    if (!marketPlaceId) return;
    try {
      setLoading(true);
      const _getFulfillmentPolicy = httpsCallableFromURL(
        functions,
        firebaseCall('getFulfillmentPolicies'),
      );
      const response = await _getFulfillmentPolicy({
        marketPlaceId,
      });

      console.log('response getFulfillmentPolicy', response);

      EbayPolicyState({
        ...EbayPolicyState(),
        fulfillmentPolicies: (
          response?.data as {
            fulfillmentPolicies: IEbayPolicy['fulfillmentPolicies'],
          }
        )?.fulfillmentPolicies,
      });

      setLoading(false);
    } catch (error) {
      console.error('Error createReturnPolicy', error);
      setLoading(false);
    }
  };

  const createPaymentPolicy = async (
    policy: IEbayPolicy['paymentPolicies'][0],
  ) => {
    try {
      updateModal({
        loading: true,
        error: null,
      });
      setLoading(true);
      const _createPaymentPolicy = httpsCallableFromURL(
        functions,
        firebaseCall('createPaymentPolicy'),
      );
      const response = await _createPaymentPolicy({
        policy,
      });

      console.log('response', response?.data);

      if ((response?.data as { status: number })?.status === 400) {
        updateModal({
          loading: false,
          error: (response?.data as { message: string })?.message,
        });
      } else {
        await getPaymentPolicy(policy.marketplaceId);
        updateModal({
          loading: false,
          type: null,
        });
      }

      setLoading(false);
    } catch (error) {
      console.error('Error createReturnPolicy', error);
      setLoading(false);
      updateModal({
        loading: false,
        error: 'Something went wrong, Please try again in a bit',
      });
    }
  };
  const getPaymentPolicy = async (marketPlaceId: string) => {
    if (!marketPlaceId) return;
    try {
      setLoading(true);
      const _getPaymentPolicy = httpsCallableFromURL(
        functions,
        firebaseCall('getPaymentPolicies'),
      );
      const response = await _getPaymentPolicy({
        marketPlaceId,
      });

      console.log('response getPaymentPolicy', response?.data);

      EbayPolicyState({
        ...EbayPolicyState(),
        paymentPolicies: (
          response?.data as {
            paymentPolicies: IEbayPolicy['paymentPolicies'],
          }
        )?.paymentPolicies,
      });

      setLoading(false);
    } catch (error) {
      console.error('Error createReturnPolicy', error);
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   console.log('useEbayInventoryItems');
  //   (async () => {
  //     const res = await getEbayInventoryItems(20, 0);
  //     console.log('EBAY result', res);
  //   })();
  // }, []);

  return {
    loading,
    listInventoryItemOnEbay,
    createDraftInventoryItemForEbay,
    getInventoryLocations,
    createInventoryLocation,
    createReturnPolicy,
    getReturnPolicy,
    getFulfillmentPolicy,
    getPaymentPolicy,
    createFulfillmentPolicy,
    createPaymentPolicy,
    getEbayInventoryItems,
    showToast,
    setShowToast,
    toastMessage,
    listingUrl,
  };
};

export default useEbayInventoryItem;
