import { Box, Modal, Typography, useTheme, IconButton } from '@mui/material';
import React from 'react';
import { CENTER } from '../../utils/CONSTS/PRESTYLES';
import { type ModalStateType } from '../../stateManagement/ModalStateManager';
import CreditSystem from '../../pages/info/CreditSystem';
import CloseIcon from '@mui/icons-material/Close';

interface CreditSystemModalProps {
  visible: boolean;
  onClose: () => void;
  modalState: ModalStateType;
}

const CreditSystemModal = ({
  visible,
  onClose,
  modalState,
}: CreditSystemModalProps) => {
  const { palette } = useTheme();

  return (
    <Modal
      open={visible}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        ...CENTER,
      }}
      BackdropProps={{
        sx: {
          backgroundColor: 'rgba(0,0,0,0.5)',
        },
      }}
    >
      <Box
        sx={{
          background: '#FFFFFF',
          width: { xs: '95%', sm: '80%', lg: '70%' },
          maxWidth: '1000px',
          maxHeight: '90vh',
          overflowY: 'auto',
          position: 'relative',
          borderRadius: 2,
          boxShadow: '0 8px 32px rgba(0,0,0,0.1)',
          '&::-webkit-scrollbar': {
            width: '8px',
          },
          '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#c1c1c1',
            borderRadius: '4px',
            '&:hover': {
              background: '#a1a1a1',
            },
          },
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: 16,
            right: 16,
            color: '#666',
            zIndex: 1,
            transition: 'all 0.3s ease',
            '&:hover': {
              color: '#333',
              transform: 'rotate(90deg)',
            },
          }}
        >
          <CloseIcon />
        </IconButton>
        <CreditSystem />
      </Box>
    </Modal>
  );
};

export default CreditSystemModal;
