/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeVar, useReactiveVar } from '@apollo/client';

export interface ModalStateType {
  open?: boolean;
  item?: string | null;
  title?: string | null;
  subtitle?: string | null;
  type?:
    | 'OPERATION'
    | 'INVENTORY_LOCATION'
    | 'RETURN_POLICY'
    | 'FULFILLMENT_POLICY'
    | 'PAYMENT_POLICY'
    | 'EBAY_LISTING_VARIATION_PRICE'
    | 'EBAY_CONNECT_ACCOUNT'
    | 'EBAY_SETTING_COMPLETED'
    | 'WARNING'
    | 'ADD_STORE'
    | 'CREDIT_SYSTEM'
    | 'ALIEXPRESS_TOKEN'
    | 'AE_PRODUCT_DETAIL'
    | null;
  error?: string | null;
  data?: any;
  onAction?: (data?: any) => void;
  loading?: boolean;
  onActionText?: string | null;
  onActionColor?: string | null;
}

export const ModalStateManager = makeVar<ModalStateType>({
  open: false,
  item: null,
  title: null,
  type: null,
  data: null,
  loading: false,
  onAction: () => {},
  onActionText: null,
  onActionColor: null,
});

export const useModalStateManager = (): [
  modalState: ModalStateType | any,
  updateModalState: (newState: ModalStateType) => void,
  resetModalState: () => void,
] => {
  const modalState = useReactiveVar(ModalStateManager);

  const updateModalState = (newState: ModalStateType) => {
    ModalStateManager({
      ...modalState,
      ...newState,
    });
  };

  const resetModalState = () => {
    ModalStateManager({
      open: false,
      item: null,
      title: null,
      type: null,
      data: null,
      loading: false,
      onAction: () => {},
    });
  };

  return [modalState, updateModalState, resetModalState];
};
