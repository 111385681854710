export const calculateSellingPrice = ({
  purchasePrice,
  shippingPrice,
  profitPercent,
  ebayFeePercent,
  ebayFixedFee,
}: {
  purchasePrice: number,
  shippingPrice: number,
  profitPercent: number,
  ebayFeePercent: number,
  ebayFixedFee: number,
}) => {
  const totalCost = purchasePrice + shippingPrice;

  // 1) Price needed to achieve desired profit
  const totalPrice =
    ((1 + profitPercent / 100) * totalCost + ebayFixedFee) /
    (1 - ebayFeePercent / 100);

  // 2) Actual profit after fees
  const totalProfit =
    totalPrice -
    (totalCost + (ebayFeePercent / 100) * totalPrice + ebayFixedFee);

  return {
    totalPrice: parseFloat(totalPrice.toFixed(2)),
    totalProfit: parseFloat(totalProfit.toFixed(2)),
  };
};
