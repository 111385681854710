/* eslint-disable @typescript-eslint/no-unnecessary-boolean-literal-compare */
import React, { useEffect, useRef, Suspense } from 'react';
import './App.css';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { app } from './config/firebase';
import useIsLoggedIn from './utils/hooks/useIsLoggedin';
import Loading from './components/Loading';
import useSyncUser from './utils/hooks/useUser';
import { ThemeProvider } from '@emotion/react';
import { Container, CssBaseline, Typography } from '@mui/material';
import useThemeProvider from './theme';
import { useUser } from './stateManagement/userStateManager';
import { useModalStateManager } from './stateManagement/ModalStateManager';
import Box from './components/Box';
import Logo from './components/Logo';
import COLORS from './utils/CONSTS/COLORS';
import OneSignal from 'react-onesignal';
import { EbayInventoryLocationsState } from './stateManagement/EbayInventoryLocationsState';
import EbayLocationInventoryModal from './components/modals/EbayLocationInventoryModal';
import EbayReturnPolicyModal from './components/modals/EbayReturnPolicyModal';
import EbayFulfillmentPolicyModal from './components/modals/EbayFulfillmentPolicyModal';
import EbayPaymentPolicyModal from './components/modals/EbayPaymentPolicyModal';
import EbayVariantEditorModal from './components/modals/EbayVariantEditorModal';
import OperationLoadingModal from './components/modals/OperationLoadingModal';
import useSyncOrganisationAccounts from './utils/hooks/useSyncOrganisationAccounts';
import { useReactiveVar } from '@apollo/client';
import { OrganisationAccounts } from './stateManagement/OrganisationAccounts';
import CookieBanner from './components/CookieBanner';
import Cookies from 'js-cookie';
import { getAnalytics } from 'firebase/analytics';
import { type FirebaseApp } from 'firebase/app';
import { SelectedOrganisationAccountState } from './stateManagement/SelectedOrganisationAccount';
import EbayConnectAccountModal from './components/modals/EbayConnectAccountModal';
import EbaySettingCompletedModal from './components/modals/EbaySettingCompletedModal';
import WarningModal from './components/modals/WarningModal';
import AddStoreModal from './components/modals/AddStoreModal';
import CreditSystemModal from './components/modals/CreditSystemModal';
import AliExpressTokenModal from './components/modals/AliExpressTokenModal';
import AEProductDetailModal from './components/modals/AEProductDetailModal';

// Lazy load components
const LoginPage = React.lazy(async () => await import('./pages/auth/LoginPage'));
const RegisterPage = React.lazy(async () => await import('./pages/auth/RegisterPage'));
const SearchAmazonMarket = React.lazy(async () => await import('./pages/dashboard/SearchAmazonMarket'));
const DashboardLayout = React.lazy(async () => await import('./layouts/DashboardLayout'));
const LoggedInAppLayout = React.lazy(async () => await import('./layouts/LoggedInAppLayout'));
const UserDetail = React.lazy(async () => await import('./pages/user/UserDetail'));
const ComparesLandingPage = React.lazy(async () => await import('./pages/compare/ComparesLandingPage'));
const CompareListDetailPage = React.lazy(async () => await import('./pages/compare/CompareListDetailPage'));
const CompareDetailPage = React.lazy(async () => await import('./pages/compare/CompareDetailPage'));
const ComparesFavoritePage = React.lazy(async () => await import('./pages/compare/ComparesFavoritePage'));
const Subscription = React.lazy(async () => await import('./pages/subscription/Subscription'));
const NicheLabLanding = React.lazy(async () => await import('./pages/nicheLab/NicheLabLanding'));
const NicheLabSearchDetail = React.lazy(async () => await import('./pages/nicheLab/NicheLabSearchDetail'));
const HomeScreen = React.lazy(async () => await import('./pages/homeScreen/HomeScreen'));
const AppLandingScreen = React.lazy(async () => await import('./pages/app/AppLandingScreen/AppLandingScreen'));
const AIProductSuggestions = React.lazy(async () => await import('./pages/app/AIProductSuggestions/AIProductSuggestions'));
const PrivacyPolicy = React.lazy(async () => await import('./pages/legalPages/privacyPolicy'));
const TermsAndConditions = React.lazy(async () => await import('./pages/legalPages/TermsAndCondition'));
const AEAuthCode = React.lazy(async () => await import('./pages/authCodes/AEAuthCode'));
const EbayAuthCode = React.lazy(async () => await import('./pages/authCodes/EbayAuthCode'));
const EbayListingDetail = React.lazy(async () => await import('./pages/app/ebay/EbayListingDetail'));
const EbayListingLandingPage = React.lazy(async () => await import('./pages/app/ebay/EbayListingLandingPage'));
const UserProfileLanding = React.lazy(async () => await import('./pages/userProfile/UserProfileLanding'));
const CreateOrganisationAccount = React.lazy(async () => await import('./pages/createOrganisationAccount/CreateOrganisationAccount'));
const VerifyEmail = React.lazy(async () => await import('./pages/verifyEmail'));
const PasswordResetPage = React.lazy(async () => await import('./pages/auth/PasswordResetPage'));
const DeleteAccountPage = React.lazy(async () => await import('./pages/settings/DeleteAccountPage'));
const SettingsLanding = React.lazy(async () => await import('./pages/settings/SettingsLanding'));
const SetupEbayAccount = React.lazy(async () => await import('./pages/app/ebay/SetupEbayAccount'));

// eslint-disable-next-line prettier/prettier
declare global {
  interface Window {
    promotekit_referral: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    opera: any;
  }
}

const isMobileBrowser = (): boolean => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return /android|iphone|ipad|mobile/i.test(userAgent as string);
};

function App() {
  const isLoggedIn = useIsLoggedIn();
  const { user, permissionStatus, isEmailVerified } = useUser();
  const theme = useThemeProvider();
  const navigate = useNavigate();
  const isInitialised = useRef(false);
  const isMobile = useRef(isMobileBrowser());

  // Initialize OneSignal
  useEffect(() => {
    if (typeof window !== 'undefined' && !isInitialised.current) {
      console.log('init onesignal');
      OneSignal.init({
        appId: process.env.REACT_APP_onesignal_app_id as string,
        notifyButton: {
          enable: true,
        },
      });
      isInitialised.current = true;
    }
  }, []);

  const [modalState, updateModalState, resetModal] = useModalStateManager();

  const initializeAnalytics = () => {
    if (Cookies.get('userConsent') === 'true' && app) {
      // Initialize Google
      if (process.env.NODE_ENV === 'production') getAnalytics(app);
    }
  };

  // Initialize Firebase Analytics
  useEffect(() => {
    initializeAnalytics();
  }, []);

  // Sync user data
  useSyncUser();

  // Handle subscription status
  useEffect(() => {
    if (permissionStatus !== undefined && !permissionStatus?.hasAccess) {
      navigate('/');
    }
    if (user) {
      OneSignal.User.addTag('hasSubscription', String(!!permissionStatus?.hasAccess));
    }
  }, [permissionStatus, user, navigate]);

  // Sync organization accounts
  useSyncOrganisationAccounts();

  const organisationAccounts = useReactiveVar(OrganisationAccounts);
  const selectedOrganisation = useReactiveVar(SelectedOrganisationAccountState);
  const hasOrganisationAccount = organisationAccounts && organisationAccounts?.length > 0;
  const hasSubscription = permissionStatus?.hasAccess;

  // Show loading state while authentication is being determined
  if (
    isLoggedIn === undefined ||
    (isLoggedIn === true && !user) ||
    (isLoggedIn === true && permissionStatus === undefined && user?.currentOrganisationId) ||
    organisationAccounts === undefined
  ) {
    return (
      <Box center flex width="100vw" height="100vh">
        <Loading size={50} />
      </Box>
    );
  }

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <CookieBanner />
        <Suspense fallback={
          <Box center flex width="100vw" height="100vh" backgroundColor={'black'}>
            <Loading size={100} />
          </Box>
        }>
          <Routes>
            {isLoggedIn === false && (
              <Route>
                <Route index element={<HomeScreen />} />
                <Route path="login" element={<LoginPage />} />
                <Route path="signup" element={<RegisterPage />} />
                <Route path="password-reset" element={<PasswordResetPage />} />
              </Route>
            )}

            {isLoggedIn === true && !isEmailVerified && (
              <Route>
                <Route index element={<VerifyEmail />} />
              </Route>
            )}

            {isLoggedIn === true && !hasOrganisationAccount && isEmailVerified && (
              <Route>
                <Route index element={<CreateOrganisationAccount />} />
              </Route>
            )}

            {isLoggedIn === true && !hasSubscription && hasOrganisationAccount && isEmailVerified && (
              <Route path="/">
                <Route index element={<Subscription />} />
              </Route>
            )}

            {isLoggedIn === true && hasSubscription && hasOrganisationAccount && isEmailVerified && (
              <>
                {isMobile.current ? (
                  <Route
                    path="/"
                    element={
                      <Container sx={{ backgroundColor: COLORS.BLACK }}>
                        <Box center flex width="100vw" height="100vh" style={{ flexDirection: 'column' }}>
                          <Logo size={200} />
                          <Typography variant="h6" mt={3} sx={{ textAlign: 'center', color: COLORS.WHITE }}>
                            We are currently not supporting mobile devices. Please use a desktop or laptop to access our platform.
                          </Typography>
                        </Box>
                      </Container>
                    }
                  />
                ) : (
                  <Route path="/" element={<LoggedInAppLayout />}>
                    <Route element={<DashboardLayout />}>
                      <Route index element={<AppLandingScreen />} />
                      <Route path="compares" element={<ComparesLandingPage />} />
                      <Route path="compare-list/:id" element={<CompareListDetailPage />} />
                      <Route path="compare/:id" element={<CompareDetailPage />} />
                      <Route path="compares/favorites" element={<ComparesFavoritePage />} />
                      <Route path="subscription" element={<Subscription />} />
                      <Route path="search-market" element={<SearchAmazonMarket />} />
                      <Route path="user/:id" element={<UserDetail />} />
                      <Route path="nichelab" element={<NicheLabLanding />} />
                      <Route path="nichelab/:id" element={<NicheLabSearchDetail />} />
                      <Route path="ai-product-suggestions" element={<AIProductSuggestions />} />
                      <Route path="ebay-listings/:id" element={<EbayListingDetail />} />
                      <Route path="ebay-listings" element={<EbayListingLandingPage />} />
                      <Route path="profile" element={<UserProfileLanding />} />
                      <Route path="settings" element={<SettingsLanding />} />
                      <Route path="delete-account" element={<DeleteAccountPage />} />
                    </Route>
                    <Route path="setup-ebay-account" element={<SetupEbayAccount />} />
                    <Route path="ae-auth" element={<AEAuthCode />} />
                    <Route path="ebay-auth" element={<EbayAuthCode />} />
                  </Route>
                )}
              </>
            )}

            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="terms-and-conditions" element={<TermsAndConditions />} />
          </Routes>
        </Suspense>

        {/* Modals */}
        {modalState?.type === 'OPERATION' && (
          <OperationLoadingModal
            visible={modalState?.type === 'OPERATION'}
            modalState={modalState}
            onClose={() => {
              updateModalState({ type: null });
            }}
          />
        )}
        {modalState?.type === 'INVENTORY_LOCATION' && (
          <EbayLocationInventoryModal
            visible={modalState?.type === 'INVENTORY_LOCATION'}
            modalState={modalState}
            onClose={() => {
              updateModalState({ type: null });
            }}
          />
        )}
        {modalState?.type === 'RETURN_POLICY' && (
          <EbayReturnPolicyModal
            visible={modalState?.type === 'RETURN_POLICY'}
            modalState={modalState}
            onClose={() => {
              updateModalState({ type: null });
            }}
          />
        )}
        {modalState?.type === 'FULFILLMENT_POLICY' && (
          <EbayFulfillmentPolicyModal
            visible={modalState?.type === 'FULFILLMENT_POLICY'}
            modalState={modalState}
            onClose={() => {
              updateModalState({ type: null });
            }}
          />
        )}
        {modalState?.type === 'PAYMENT_POLICY' && (
          <EbayPaymentPolicyModal
            visible={modalState?.type === 'PAYMENT_POLICY'}
            modalState={modalState}
            onClose={() => {
              updateModalState({ type: null });
            }}
          />
        )}
        {modalState?.type === 'EBAY_LISTING_VARIATION_PRICE' && (
          <EbayVariantEditorModal
            visible={modalState?.type === 'EBAY_LISTING_VARIATION_PRICE'}
            modalState={modalState}
            onClose={() => {
              updateModalState({ type: null });
            }}
          />
        )}
        {modalState?.type === 'EBAY_CONNECT_ACCOUNT' && (
          <EbayConnectAccountModal
            visible={modalState?.type === 'EBAY_CONNECT_ACCOUNT'}
            modalState={modalState}
            onClose={() => {
              updateModalState({ type: null });
            }}
          />
        )}
        {modalState?.type === 'EBAY_SETTING_COMPLETED' && (
          <EbaySettingCompletedModal
            visible={modalState?.type === 'EBAY_SETTING_COMPLETED'}
            modalState={modalState}
            onClose={() => {
              updateModalState({ type: null });
            }}
          />
        )}
        {modalState?.type === 'WARNING' && (
          <WarningModal
            visible={modalState?.type === 'WARNING'}
            modalState={modalState}
            onClose={() => {
              updateModalState({ type: null });
            }}
          />
        )}
        {modalState?.type === 'ADD_STORE' && (
          <AddStoreModal
            visible={modalState?.type === 'ADD_STORE'}
            modalState={modalState}
            onClose={() => {
              resetModal();
            }}
          />
        )}
        {modalState?.type === 'CREDIT_SYSTEM' && (
          <CreditSystemModal
            visible={modalState?.type === 'CREDIT_SYSTEM'}
            modalState={modalState}
            onClose={() => {
              resetModal();
            }}
          />
        )}
        {modalState?.type === 'ALIEXPRESS_TOKEN' && (
          <AliExpressTokenModal
            visible={modalState?.type === 'ALIEXPRESS_TOKEN'}
            modalState={modalState}
            onClose={() => {
              resetModal();
            }}
          />
        )}
        {modalState?.type === 'AE_PRODUCT_DETAIL' && (
          <AEProductDetailModal
            visible={modalState?.type === 'AE_PRODUCT_DETAIL'}
            modalState={modalState}
            onClose={() => {
              resetModal();
            }}
          />
        )}
      </ThemeProvider>
    </div>
  );
}

export default App;
